import React, { useEffect, useState, Fragment, useRef } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import HeaderSEO from '../components/partials/HeaderSEO'
import Logos from '../components/partials/Logos'
import { StaticImage } from 'gatsby-plugin-image'
// import { ReactCompareSlider, ReactCompareSliderHandle } from 'react-compare-slider'
import Lottie from '../components/lottie/Lottie'

// import ReactTypingEffect from 'react-typing-effect'

import Spacing from '../components/partials/Spacing'

// import Item1 from '../components/homepage-slider/item1'
// import Item2 from '../components/homepage-slider/item2'

import Layout from '../components/Layout'
// Hero Images

// import aiven_crab from '../resources/images/homepage/logos/aiven-crab.svg'

// import aiven from '../resources/images/Homepage/logos/aiven-white.svg'
// import azure from '../resources/images/Homepage/logos/azure-white.svg'
// import instaclustr from '../resources/images/Homepage/logos/instaclustr-white.svg'
// import msk from '../resources/images/Homepage/logos/msk-white.svg'
// import confluent from '../resources/images/Homepage/logos/confluent-white.svg'

import { initializeVideos } from '../helpers/initializeVideo'
import { navbarScrolling } from '../helpers/navbarScrolling'
import { triggerTyping } from '../helpers/typingAnimation'
import BlogSection from '../components/sections/BlogSection'

declare const window: any

interface StateProps {
    splitter: SplitterProps
}

interface SplitterProps {
    splitterPosition: number
    hasBeenMoved: boolean
}

const RootIndex: React.FC<StateProps> = () => {
    const data = useStaticQuery(graphql`
        query LensesHomeSEO {
            allContentfulPages(filter: { pageReference: { pageIdentifier: { eq: "Homepage" } } }) {
                edges {
                    node {
                        title {
                            title
                        }
                        keywords {
                            keywords
                        }
                        description {
                            description
                        }
                        oGimage {
                            fluid(quality: 100) {
                                src
                            }
                        }
                    }
                }
            }
        }
    `)

    const pageSEO = data.allContentfulPages.edges[0].node

    const [splitter, setSplitterPosition] = useState<SplitterProps>({
        splitterPosition: 100,
        hasBeenMoved: false,
    })
    const ref = useRef(null)
    const [video, setVideo] = useState({ isOpen: false, title: '', url: '' })
    // const [hoverObject, setHoverObject] = useState<'devx' | 'connectors'>('devx')

    const myStateRef = React.useRef(splitter.hasBeenMoved)

    const setMyState = () => {
        myStateRef.current = true
        setSplitterPosition({ splitterPosition: 50, hasBeenMoved: true })
    }

    const blogs = [
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../resources/images/new_homepage/security-operations.jpg'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline fw-500 cera-pro' style={{ fontSize: '16px' }}>
                        Kafka Security Operations: External Secrets for Kafka Connect
                    </p>
                </div>
            ),
            type: 'EPISODE',
            callback: () =>
                setVideo({
                    isOpen: true,
                    title: 'Kafka Security Operations: External Secrets for Kafka Connect',
                    url: '//player.vimeo.com/video/918009036',
                }),
            url: 'https://vimeo.com/manage/videos/918009036',
        },
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../resources/images/new_homepage/consumer-offsets.jpg'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline fw-500 cera-pro' style={{ fontSize: '16px' }}>
                        Managing Kafka Consumer Offsets
                    </p>
                </div>
            ),
            type: 'EPISODE',
            callback: () =>
                setVideo({
                    isOpen: true,
                    title: 'Managing Kafka Consumer Offsets',
                    url: '//player.vimeo.com/video/918007254',
                }),
            url: 'hhttps://vimeo.com/manage/videos/918007254',
        },
        {
            image: (
                <StaticImage
                    className='w-100 rounded-top-1'
                    src='../resources/images/new_homepage/managing-schemas.jpg'
                    placeholder='tracedSVG'
                    alt='Data Capture and Kafka'
                />
            ),
            title: (
                <div>
                    <p className='d-inline fw-500 cera-pro' style={{ fontSize: '16px' }}>
                        Understanding and Managing Schemas in Kafka
                    </p>
                </div>
            ),
            type: 'EPISODE',
            callback: () =>
                setVideo({
                    isOpen: true,
                    title: 'Understanding and Managing Schemas in Kafka',
                    url: '//player.vimeo.com/video/918002262',
                }),
            url: 'https://vimeo.com/manage/videos/918002262',
        },
    ]

    useEffect(() => {
        try {
            navbarScrolling()
            initializeVideos()
            triggerTyping()

            const checkSplitterInView = () => {
                if (!myStateRef.current) {
                    let splitterDOMElement =
                        document.querySelector<HTMLElement>('.__rcs-handle-root')
                    if (isInViewport(document.querySelector('.__rcs-handle-button'))) {
                        splitterDOMElement.style.transform = 'translateX(-850%)'
                        setTimeout(() => {
                            setMyState()
                            splitterDOMElement.style.transition = 'all 0s'
                            splitterDOMElement.style.transform = 'translateX(0%)'
                        }, 400)
                    }
                }
            }

            window.addEventListener('scroll', checkSplitterInView)
        } catch (err) {
            console.log(err)
        }
        // es-lint-disable-next-line
    }, [])

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                setVideo((current) => ({ ...current, isOpen: false }))
            }
        }

        // Bind the event listener
        document.addEventListener('mousedown', handleClickOutside)

        // Unbind the event listener on cleanup
        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [])

    // const handleLink = (index) => {
    //     if (index === 0) {
    //         return '/usecases/apache-kafka-governance/'
    //     } else if (index === 1) {
    //         return '/usecases/apache-kafka-data-observability/'
    //     } else if (index === 2) {
    //         return '/usecases/apache-kafka-security/'
    //     } else if (index === 3) {
    //         return '/usecases/kafka-streams/'
    //     } else {
    //         return '#!'
    //     }
    // }
    //
    // const handleID = (index) => {
    //     if (index === 0) {
    //         return 'homepage-typing-self-service'
    //     } else if (index === 1) {
    //         return 'homepage-typing-see-inside'
    //     } else if (index === 2) {
    //         return 'homepage-typing-secure'
    //     } else if (index === 3) {
    //         return 'homepage-typing-stream-process'
    //     }
    // }
    //
    // const submitDataToSplunk = (position) => {
    //     // To prevent onload Submissions
    //     if (myStateRef.current) {
    //         // Log data to Splunk
    //         if (position === 0 || position === 100) {
    //             const splunkData = {
    //                 position,
    //             }
    //             window.Mint.logEvent(
    //                 'action=homepage_slider value=' +
    //                     JSON.stringify(splunkData) +
    //                     ' page=' +
    //                     window.location.pathname +
    //                     ' time=' +
    //                     Date.now(),
    //             )
    //         }
    //     }
    // }

    function isInViewport(element) {
        if (!element) return
        const rect = element.getBoundingClientRect()
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom + 100 <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        )
    }

    return (
        <Fragment>
            {/* Banner Area */}
            {/* <section className="home-banner bg-dark"> */}
            {/* <img className="aiven-crab mr-2" src={aiven_crab} alt="Aiven" /> */}
            {/* <span className="f-15">
          {' '}
          Lenses 4.3 is here: Enhanced, secure developer experience for Kafka
          <a
            href="/blog/2021/07/lenses-secure-self-service-developer-experience-apache-kafka/"
            // target="_blank"
            // rel="noopener noreferrer"
            className="ml-1 text-white mobile-padding-right-half mobile-margin-reset"
          >
            | Learn more &gt;
          </a>
        </span>
      </section> */}
            <Layout newsletter={true} linkColor={'dark'} isTransparent={true}>
                <HeaderSEO pageSEO={pageSEO} />
                <div className='homepage'>
                    <section className='container-1 mx-auto heroLottieSection'>
                        <Lottie />
                    </section>
                    <Spacing />
                    <section className='mobile-margin-top mb-4 mb-sm-0'>
                        <Logos width={75} />
                    </section>
                    <Spacing />
                    <section className='peopleSection'>
                        <div className='container-2'>
                            <div className='align-items-center justify-content-center'>
                                <div className='container-1 col-12 text-center pt-4'>
                                    <h2 className='f-32'>
                                        Perfect for enterprises with 100s of Kafka users
                                    </h2>
                                </div>
                                <div className='peopleSectionFlex'>
                                    <div className='d-flex flex-wrap justify-content-between'>
                                        <div className='col-12 mobile-margin-top-half col-sm-3'>
                                            <StaticImage
                                                className=''
                                                src='../resources/images/new_homepage/PE.webp'
                                                alt='Home Lenses'
                                                placeholder='blurred'
                                            />
                                            <div className={'peopleSectionDescription'}>
                                                <h3>PLATFORM ENGINEERS</h3>
                                                <p>Overworked with endless developer requests.</p>
                                            </div>
                                        </div>
                                        <div className='col-12 mobile-margin-top-half col-sm-3'>
                                            <StaticImage
                                                className=''
                                                src='../resources/images/new_homepage/Dev.webp'
                                                alt='Home Lenses'
                                                placeholder='blurred'
                                            />
                                            <div className={'peopleSectionDescription'}>
                                                <h3>DEVELOPERS</h3>
                                                <p>
                                                    Restricted by lack of self-service to explore
                                                    data and deploy flows.
                                                </p>
                                            </div>
                                        </div>
                                        <div className='col-12 mobile-margin-top-half col-sm-3'>
                                            <StaticImage
                                                className=''
                                                src='../resources/images/new_homepage/CTO.webp'
                                                alt='Home Lenses'
                                                placeholder='blurred'
                                            />
                                            <div className={'peopleSectionDescription'}>
                                                <h3>ENGINEERING LEADERS</h3>
                                                <p>
                                                    Under pressure to increase developer
                                                    productivity and accelerate real-time projects.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Spacing />
                    <section className='selfServiceSection'>
                        <div className='container-2'>
                            <div className='container-1 col-12 text-center pt-4'>
                                <h2 className='pb-0'>Two products. Unlimited productivity.</h2>
                                <p>
                                    Build, operate and integrate your data streams and event-driven
                                    apps from one place.
                                </p>
                            </div>
                            <div className='twoProducts mt-5 pt-4'>
                                <div className='blackVariant col-12 col-lg-6 align-items-center'>
                                    <a href='/why-kafka-devx/'>
                                        <div className={''}>
                                            <h3 className='f-32 fw-300 text-white lh-42 ml-4'>
                                                Lenses Developer Experience
                                            </h3>
                                            <p className='ml-0 pl-0 text-white ml-4'>
                                                Self-service access for developers to manage streams
                                                in Apache Kafka.
                                            </p>
                                            {/*<div className='roll-button navbar-dl-button mobile-padding-left mobile-margin-top-half heroLottieButton mb-3'>*/}
                                            {/*    <a*/}
                                            {/*        id='navbar-download'*/}
                                            {/*        style={{ fontSize: '1rem' }}*/}
                                            {/*        className='text-white mr-0 bg-red'*/}
                                            {/*        href='/why-lenses/'>*/}
                                            {/*        <span data-hover='Get started'>Get started</span>*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className=''>
                                            <StaticImage
                                                className='twoProducts-img'
                                                src='../resources/images/new_homepage/DevX.png'
                                                placeholder='tracedSVG'
                                                alt='Observability'
                                            />
                                        </div>
                                    </a>
                                </div>
                                <div className='lightVariant col-12 col-lg-6 align-items-center'>
                                    <a href='/kafka-connectors/'>
                                        <div className={''}>
                                            <h3 className='f-32 fw-300 lh-42 ml-4'>
                                                Lenses Open-Source Kafka Connectors
                                            </h3>
                                            <p className='ml-4'>
                                                Source and sink data integration with enterprise
                                                support.
                                            </p>
                                            {/*<div className='roll-button navbar-dl-button mobile-padding-left mobile-margin-top-half heroLottieButtonRed '>*/}
                                            {/*    <a*/}
                                            {/*        id='navbar-download'*/}
                                            {/*        style={{ fontSize: '1rem', backgroundColor: '#5c89bf' }}*/}
                                            {/*        className='text-white mr-0'*/}
                                            {/*        href='/kafka-connectors/'>*/}
                                            {/*        <span data-hover='Learn more'>Learn more</span>*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                        </div>
                                        <div className=''>
                                            <StaticImage
                                                className='twoProducts-img'
                                                src='../resources/images/new_homepage/enterprise-connectors.png'
                                                placeholder='tracedSVG'
                                                alt='Observability'
                                            />
                                        </div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Spacing />
                    <section className='bg-usecase-arity-hp py-5 mobile-margin-top height-testimonial-homepage caseStudySection'>
                        <div className='container-1 '>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div className='col-sm-9 col-12'>
                                    <p className='f-16 text-center cera-pro text-uppercase text-white'>
                                        {' '}
                                        Case Study
                                    </p>
                                    <div className='text-center pb-4 pt-4'>
                                        <StaticImage
                                            className='img-fluid w-20'
                                            src='../resources/images/new_homepage/arity.png'
                                            placeholder='tracedSVG'
                                            alt='Article'
                                        />
                                        <div className='d-flex justify-content-center p-3'>
                                            <StaticImage
                                                className='img-fluid w-20 mr-3'
                                                src='../resources/images/new_homepage/awsXlenses.png'
                                                placeholder='tracedSVG'
                                                alt='Article'
                                            />
                                        </div>
                                    </div>
                                    <h2 className='fw-600 text-center text-white h1-like'>
                                        Revving Up Arity’s Operational Kafka Efficiency by 200% with
                                        Lenses.io and Amazon MSK
                                    </h2>
                                </div>

                                <div className='col-sm-10 col-12'>
                                    <p className='f-20 text-white text-center mb-0'>
                                        How Arity accelerated their Kafka adoption and saved costs
                                        by 40%.
                                    </p>

                                    <div className='mt-4 text-center'>
                                        <a
                                            className='primary-text'
                                            href='/customers/arity-apache-kafka-costs-amazon-msk/'>
                                            Read their story <i className='red-pointer-right'></i>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <Spacing />

                    <section>
                        <div className='container-2 mobile-margin-top'>
                            <div className='d-flex flex-wrap justify-content-center'>
                                <div className='col-sm-7 col-12'>
                                    <div className='text-center px-2'>
                                        <h2 className='fw-600 f-40 lh-52 h1-like mb-4 mb-sm-0'>
                                            From Kafka data discovery to{' '}
                                            <br className={'onlyDesktop'} /> app deployment
                                        </h2>
                                    </div>
                                </div>
                            </div>

                            <div className='d-flex flex-wrap pt-5 my-5 align-items-center mobile-margin-reset mobile-bg-light-gray mobile-padding-top mobile-padding-bottom'>
                                <div className='col-md-4 col-10'>
                                    <h3 className='f-35 fw-400 lh-42 mb-3'>
                                        Data observability & catalog
                                    </h3>
                                    <br />
                                    <p className='f-20 lh-32 fw-300 d-block mb-5'>
                                        Intelligently discover data and applications, then query
                                        Kafka topics with SQL.
                                    </p>
                                    <a
                                        className='primary-text'
                                        href='/usecases/apache-kafka-data-observability/'>
                                        Find out more <i className='red-pointer-right'></i>
                                    </a>
                                </div>
                                <div className='offset-md-1'></div>
                                <div className='col-md-7 col-12 mobile-margin-top-half'>
                                    <StaticImage
                                        className='pt-2 w-100 dropShadowUtility'
                                        src='../resources/images/new_homepage/01-observability.png'
                                        placeholder='tracedSVG'
                                        alt='Observability'
                                    />
                                </div>
                            </div>
                            <Spacing />
                            <div className='d-flex flex-wrap flex-column-reverse flex-sm-row align-items-center mobile-margin-top'>
                                <div className='col-md-7 col-12 mobile-margin-top-half'>
                                    <StaticImage
                                        className='pt-2 w-100 dropShadowUtility'
                                        src='../resources/images/new_homepage/02-self-service.png'
                                        placeholder='tracedSVG'
                                        alt='Governance'
                                    />
                                </div>
                                <div className='offset-md-1'></div>
                                <div className='col-md-4 col-12 '>
                                    <h3 className='f-35 fw-400 lh-42 mb-3'>
                                        Self-service administration
                                    </h3>
                                    <br />
                                    <p className='f-20 lh-32 fw-300 d-block mb-5'>
                                        Controls for managing topics, schemas, ACLs, quotas, and
                                        alerts.
                                    </p>
                                    <a
                                        className='primary-text text-nowrap'
                                        href='/usecases/apache-kafka-governance/'>
                                        Find out more <i className='red-pointer-right'></i>
                                    </a>
                                </div>
                            </div>
                            <Spacing />
                            <div className='d-flex flex-wrap align-items-center mobile-margin-top mobile-bg-light-gray mobile-padding-top mobile-padding-bottom'>
                                <div className='col-md-4 col-10 '>
                                    <h3 className='f-35 fw-400 lh-42 mb-3'>
                                        Security & compliance
                                    </h3>
                                    <br />
                                    <p className='f-20 lh-32 fw-300 d-block mb-5'>
                                        Apply granular IAM, auditing, and data masking designed to
                                        onboard thousands of engineers.
                                    </p>
                                    <a
                                        className='primary-text'
                                        href='/usecases/apache-kafka-security/'>
                                        Find out more <i className='red-pointer-right'></i>
                                    </a>
                                </div>
                                <div className='offset-md-1'></div>
                                <div className='col-md-7 col-12 mobile-margin-top-half'>
                                    <StaticImage
                                        className='pt-2 w-100 dropShadowUtility'
                                        src='../resources/images/new_homepage/03-security.png'
                                        placeholder='tracedSVG'
                                        alt='Security'
                                    />
                                </div>
                            </div>
                            <Spacing />
                            <div className='d-flex flex-wrap flex-column-reverse flex-sm-row align-items-center mobile-margin-top'>
                                <div className='col-md-7 col-12  mobile-margin-top-half'>
                                    <StaticImage
                                        className='pt-2 w-100 dropShadowUtility'
                                        src='../resources/images/new_homepage/04-data-movement.png'
                                        placeholder='tracedSVG'
                                        alt='Processing'
                                    />
                                </div>
                                <div className='offset-md-1'></div>
                                <div className='col-md-4 col-12 '>
                                    <h3 className='f-35 fw-400 lh-42 mb-3'>
                                        Data movement with governance
                                    </h3>
                                    <br />
                                    <p className='f-20 lh-32 fw-300 d-block mb-5'>
                                        Port data to and from Kafka with self-service connectors,
                                        integrated with your CI/CD.
                                    </p>
                                    <a className='primary-text' href='/kafka-connectors/'>
                                        Find out more <i className='red-pointer-right'></i>
                                    </a>
                                </div>
                            </div>

                            <Spacing />
                            <div className='d-flex flex-wrap align-items-center mobile-margin-top mobile-bg-light-gray mobile-padding-top mobile-padding-bottom'>
                                <div className='col-md-4 col-10 '>
                                    <h3 className='f-35 fw-400 lh-42 mb-3'>
                                        No-code stream processing
                                    </h3>
                                    <br />
                                    <p className='f-20 lh-32 fw-300 d-block mb-5'>
                                        Transform, reshape, aggregate or process streaming data with
                                        SQL via a full developer experience.
                                    </p>
                                    <a className='primary-text' href='/kafka-connectors/'>
                                        Find out more <i className='red-pointer-right'></i>
                                    </a>
                                </div>
                                <div className='offset-md-1'></div>
                                <div className='col-md-7 col-12 mobile-margin-top-half'>
                                    <StaticImage
                                        className='pt-2 w-100 dropShadowUtility'
                                        src='../resources/images/new_homepage/05-no-code.png'
                                        placeholder='tracedSVG'
                                        alt='Security'
                                    />
                                </div>
                            </div>
                        </div>
                    </section>
                    {/*<Spacing />*/}
                    {/*<section className={'evaluateSection'}>*/}
                    {/*    <div className=''>*/}
                    {/*        <div className='d-flex flex-wrap align-items-bottom'>*/}
                    {/*            <div className='container-1 col-12 text-center text-sm-left mt-3 mb-4'>*/}
                    {/*                <h2 className='f-40 lh-52 pb-0'>*/}
                    {/*                    Kafka developer productivity assessment*/}
                    {/*                </h2>*/}
                    {/*                <p className='justify-self-center align-self-center fz-18 lh-26 fw-300 mt-4 mb-3 mobile-margin-reset'>*/}
                    {/*                    Receive a score out of 100, and a bespoke report on how your*/}
                    {/*                    enterprise compares to others in designing a*/}
                    {/*                    developer-friendly streaming platform.*/}
                    {/*                </p>*/}
                    {/*                <div className='roll-button navbar-dl-button mobile-padding-left mobile-margin-top-half heroLottieButton'>*/}
                    {/*                    <a*/}
                    {/*                        id='navbar-download'*/}
                    {/*                        style={{ fontSize: '1rem' }}*/}
                    {/*                        className='text-white mr-0 bg-red'*/}
                    {/*                        href='/start/'>*/}
                    {/*                        <span data-hover='Start free'>Start free</span>*/}
                    {/*                    </a>*/}
                    {/*                </div>*/}
                    {/*            </div>*/}
                    {/*            <StaticImage*/}
                    {/*                className='pt-2 w-30 evaluateSectionAssesment onlyDesktop'*/}
                    {/*                src='../resources/images/new_homepage/assessment-image.webp'*/}
                    {/*                placeholder='tracedSVG'*/}
                    {/*                alt='Security'*/}
                    {/*            />*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</section>*/}
                    {/*  */}
                    <Spacing />
                    <section>
                        <div className='container-1 mt-5 mt-sm-0'>
                            <div className='d-flex flex-wrap'>
                                <div className='col-12 text-center mb-5'>
                                    <h2 className='f-40 lh-52 pb-0 mb-4 mb-sm-0 cera-pro'>
                                        Kafka resource hub
                                    </h2>

                                    <p className='justify-self-center align-self-center fz-18 lh-26 fw-300 mt-5 mb-3 mobile-margin-reset'>
                                        Free Apache Kafka Training with Lenses. 30 minutes. Every
                                        week.
                                    </p>

                                    <a
                                        rel={'noreferrer'}
                                        className='primary-text text-nowrap'
                                        href='https://celonis.zoom.us/webinar/register/WN_8sa_B5O6SSSKHPWft11O1w#/registration'
                                        target={'_blank'}>
                                        Kafka Live Stream | Register{' '}
                                        <i className='red-pointer-right'></i>
                                    </a>
                                </div>
                                <div className='col-md col-sm-6 col-12 mt-1'>
                                    <BlogSection blogs={blogs} />
                                </div>
                            </div>
                        </div>
                    </section>
                    <Spacing />
                    <section className={' mt-5 mt-sm-0'}>
                        <div className='sectionWithGuyBlackStrip'>
                            <div className='d-flex flex-wrap align-items-bottom'>
                                <div
                                    className='container-1 col-12 text-left mt-3 mb-4'
                                    style={{ maxHeight: '170px' }}>
                                    <h2 className='f-40 lh-52 pb-0 text-white'>
                                        Build better, sleep easy with the{' '}
                                        <br className={'onlyDesktop'} /> developer experience for
                                        any Kafka
                                    </h2>
                                    <div className='roll-button navbar-dl-button heroLottieButton'>
                                        <a
                                            id='navbar-download'
                                            style={{ fontSize: '1rem' }}
                                            className='text-white mr-0 bg-red'
                                            href='/start/'>
                                            <span data-hover='Start free'>Start free</span>
                                        </a>
                                    </div>
                                </div>
                                <StaticImage
                                    className='pt-2 w-35 sectionWithGuyImage'
                                    src='../resources/images/new_homepage/developer-experience.webp'
                                    placeholder='tracedSVG'
                                    alt='Security'
                                />
                            </div>
                        </div>
                    </section>
                </div>
                {/*  */}
                {/*<section className='bg-light-blue mobile-margin-top'>*/}
                {/*    <div className='container-1'>*/}
                {/*        <div className='d-flex flex-wrap align-items-center justify-content-center mobile-margin-reset'>*/}
                {/*            <div className='col-12 text-center py-4 bg-homepage-roi'>*/}
                {/*                <div className='mobile-margin-right-0 mobile-padding-reset text-white'>*/}
                {/*                    <div className='pb-3'>*/}
                {/*                        <span className='text-white fw-500 text-uppercase f-16 cera-pro'>*/}
                {/*                            MATURITY & ROI CALCULATOR*/}
                {/*                        </span>*/}
                {/*                    </div>*/}
                {/*                    <span>*/}
                {/*                        <p className='f-24 lh-30 text-white mb-0 cera-pro'>*/}
                {/*                            <strong> Turning Kafka into ca$h</strong>*/}
                {/*                        </p>{' '}*/}
                {/*                    </span>*/}
                {/*                </div>*/}
                {/*                <div className='roll-button pb-4 mt-4'>*/}
                {/*                    <a*/}
                {/*                        className='bg-white primary-text button-mobile-font-size link-color'*/}
                {/*                        href='/kafka-roi-calculator/'>*/}
                {/*                        <span data-hover='Try now'>Try now</span>*/}
                {/*                    </a>*/}
                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>*/}
                {/*  */}
                {/*<Spacing />*/}
                {/*<section>*/}
                {/*    <div className='container-1'>*/}
                {/*        <div className='col-12 px-5 pb-4 pt-5 text-center'>*/}
                {/*            <p className='h4-like mobile-margin-reset px-1 f-19 fw-500'>*/}
                {/*                FEATURED IN*/}
                {/*            </p>*/}
                {/*        </div>*/}
                {/*        <div className='d-flex justify-content-between flex-wrap align-items-center mobile-margin-reset mobile-w-100 pb-4'>*/}
                {/*            <div className='offset-sm-1 offset-0'></div>*/}
                {/*            <div className='col-sm-2 col-6'>*/}
                {/*                <StaticImage*/}
                {/*                    className='img-fluid'*/}
                {/*                    src='../resources/images/Homepage/featuredIn/theNewStack.png'*/}
                {/*                    placeholder='tracedSVG'*/}
                {/*                    alt='TheNewStack'*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className='col-sm-2 col-6'>*/}
                {/*                <StaticImage*/}
                {/*                    className='img-fluid'*/}
                {/*                    src='../resources/images/Homepage/featuredIn/forbes.png'*/}
                {/*                    placeholder='tracedSVG'*/}
                {/*                    alt='Forbes'*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className='col-sm-2 col-6'>*/}
                {/*                <StaticImage*/}
                {/*                    className='img-fluid'*/}
                {/*                    src='../resources/images/Homepage/featuredIn/bigData.png'*/}
                {/*                    placeholder='tracedSVG'*/}
                {/*                    alt='InsideBigData'*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className='col-sm-2 col-6'>*/}
                {/*                <StaticImage*/}
                {/*                    className='img-fluid'*/}
                {/*                    src='../resources/images/Homepage/featuredIn/devOps.png'*/}
                {/*                    placeholder='tracedSVG'*/}
                {/*                    alt='DevOps.com'*/}
                {/*                />*/}
                {/*            </div>*/}
                {/*            <div className='offset-1 offset-sm-1'></div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>{' '}*/}
                {/*<Spacing />*/}
                {/*  */}
                {video.isOpen && (
                    <div>
                        <div className={'videoPopupBg'} />
                        <div
                            className='videoContainer'
                            ref={ref}
                            style={{
                                position: 'fixed',
                                boxShadow: '0 4px 25px 22px rgba(0, 0, 0, 0.3)',
                                zIndex: 9999,
                                width: '100%',
                                height: '100%',
                                maxWidth: '992px',
                                maxHeight: '600px',
                                top: '50%',
                                left: '50%',
                                transform: 'translate(-50%, -50%)',
                            }}>
                            {/*<StaticImage*/}
                            {/*    src={'../../resources/images/icons/close-icon.svg'}*/}
                            {/*    alt={'close icon'}*/}
                            {/*    className={'webinar-close-btn'}*/}
                            {/*    onClick={() => setVideo((current) => ({...current, isOpen: false}))}*/}
                            {/*/>*/}
                            <iframe
                                src={video.url}
                                title={video.title}
                                style={{
                                    // position: 'absolute',
                                    background: '#000',
                                    width: '100%',
                                    height: '100%',
                                }}
                                frameBorder='0'
                                allow='autoplay; fullscreen; picture-in-picture'
                                allowFullScreen></iframe>
                        </div>
                    </div>
                )}
            </Layout>
        </Fragment>
    )
}

export default RootIndex

import React, { createRef, useEffect } from "react";

import lottie from 'lottie-web';

import animationData from './data.json';
import { StaticImage } from 'gatsby-plugin-image'

const Lottie = () => {



    let animationContainer = createRef();

    let anim = null;

    useEffect(() => {

        anim = lottie.loadAnimation({
            container: animationContainer.current,
            renderer: "svg",
            loop: true,
            autoplay: true,
            animationData: animationData
        });

        return () => anim.destroy(); // optional clean up for unmounting
    }, [])

    return (
        <div style={{position: 'relative'}}>
            <div className={'animationWrap'}>
                <StaticImage
                    className='heroImageLottie'
                    src='./Illustration.png'
                    alt='Home Lenses'
                    placeholder='blurred'
                />
                <div ref={animationContainer} className={'heroImageLottieAnimation'}></div>
            </div>
            <div className={'heroLottieText'}>
                <h1>Every Apache Kafka. One Experience.</h1>
                <p>
                    Lenses is the leading Developer Experience <br/> for exploring and moving real-time data, across any Kafka and any cloud.


                </p>
                <div className='roll-button navbar-dl-button  heroLottieButton'>
                    <a
                        id='navbar-download'
                        style={{ fontSize: '1rem' }}
                        className='text-white mr-0 bg-red'
                        href='/start/'>
                        <span data-hover='Start free'>Start free</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Lottie;